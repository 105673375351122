<template>
	<div class="full-height flex-column">
		<PaymentInfo
			:user="user"
			:item="item"
			class="mt-50 "

			@onLoad="setPointInfo"
		>
		</PaymentInfo>

		<div
			class="pa-10-20"
		>
			<div class="justify-space-between ">
				<button
					class="flex-1 btn_l btn_left size-px-13"
					:class="is_charge ? 'btn_outline_blue' : 'btn_outline_gray'"
					@click="to('+')"
				>{{ $language.point_result.btn_add_list }} <!-- 적립내역 --></button>
				<button
					class="flex-1 btn_l btn_right size-px-13"
					:class="is_use ? 'btn_outline_blue' : 'btn_outline_gray'"
					@click="to('-')"
				>{{ $language.point_result.btn_use_list }} <!-- 사용내역 --></button>
			</div>
			<div class="mt-20">
				<DatePicker class="mt-20" type="year" :date="search_year" @click="setDate($event)" ></DatePicker>
			</div>

			<div
				class="mt-20"
			>
				<ul
					v-if="items_list.length > 0"
					class="size-px-14"
				>
					<li
						v-for="(history, h_index) in items_list"
						:key="'history_' + h_index"
						class="under-line-not-last pb-20 mb-20"
					>
						<div
							class="justify-space-between items-center"
						>
							<div>{{ $language.point.txt_heart }} <!--불렛--></div>
							<div
								class="font-weight-bold"
								:class="history.type == '+' ? 'color-blue' : ' color-red'"
							>{{ history.type}}{{ history.accumulate_point | makeComma }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point.title_div }} <!-- 구분 --></div>
							<div>{{ history.payment_type_code_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point.title_detail }} <!-- 상세 --></div>
							<div> {{ history.point_accumulate_desctn }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point.title_date }} <!-- 일시 --></div>
							<div>{{ history.point_accumulatedate }}</div>
						</div>
					</li>
				</ul>

				<Empty
					v-else
				>no data</Empty>
			</div>
		</div>

	</div>
</template>

<script>

import PaymentInfo from "@/view/Payment/PointInfo";
import DatePicker from "@/components/DatePicker";
import Empty from "@/view/Layout/Empty";
export default {
	name: 'PaymentList'
	, components: {Empty, DatePicker, PaymentInfo}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: 'MY 불렛'
				, not_footer: true
				, type: 'mypage'
				, is_save: true
				, callBack: {
					name: 'to_payment_history'
					, title: '결제내역'
				}
			}
			, item: {
				point: 0
			}
			, items: [
			]
			, items_use: [

			]
			, item_payment: {

			}
			, is_on_payment: false
			, type: this.$route.params.type ? this.$route.params.type : '+'
			, search_year: ''
			, item_point: {

			}
		}
	}
	, computed: {
		is_charge: function(){
			let t = false
			if(this.type != '-'){
				t = true
			}
			return t
		}
		, is_use: function(){
			let t = false
			if(this.type == '-'){
				t = true
			}
			return t
		}
		, items_list: function(){
			let t = this.items
			if(this.type == '-'){
				t = this.items_use
			}
			return t.filter((item) => {

				if(item.point_accumulate_code == 'PT00300001' || item.point_accumulate_code == 'PT00300004'){
					item.type = '+'
					item.payment_type_code_name = item.point_accumulate_code_name ? item.point_accumulate_code_name : item.point_use_code_name
					item.point_accumulate_desctn = item.point_accumulate_desctn ? item.point_accumulate_desctn : '-'
				}else{
					item.type = '-'
					item.accumulate_point = item.accumulate_point ? item.accumulate_point : item.use_point
					item.payment_type_code_name = item.point_accumulate_code_name ? item.point_accumulate_code_name : item.point_use_code_name
					item.point_accumulatedate = item.point_accumulatedate ? item.point_accumulatedate : item.point_useday
					item.point_accumulate_desctn = item.point_accumulate_desctn ? item.point_accumulate_desctn : item.point_use_desctn ? item.point_use_desctn : '-'
				}
				return item

			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_point_history
					, data: {
						member_number: this.user.member_number
						, point_number: this.item_point.point_number
						, search_year: this.search_year + ''
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.point_accumulate_particulars_list
					this.items_use = result.data.point_use_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_request_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPayment: function(item){
			this.is_on_payment = true
			this.item_payment = item
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, to: function(type){
			this.type = type
			// this.$bus.$emit('to', { name: 'PointHistory', params: { type: type}})
		}
		, setDate: function(date){
			this.search_year = date
			this.getData()
		}
		, setPointInfo: function(item){
			this.item_point = item
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_payment_history'){
				this.$emit('to', { name: 'PaymentHistory'})
			}
		})
	}
}
</script>
